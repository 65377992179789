import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// components
import CircularLoading from './Loading/CircularLoading';

// context
import AuthState from '../context/auth/AuthState';
import UserState from '../context/user/UserState';
import AuthContext from '../context/auth/authContext';
import DepartmentState from '../context/department/DepartmentState';
import MunicipalityState from '../context/municipality/MunicipalityState';
import PreventionState from '../context/prevention/PreventionState';
import LawState from '../context/law/LawState';
import TestimonialState from '../context/testimonial/TestimonialState';
import GameState from '../context/game/GameState';
import InstanceState from '../context/instance/InstanceState';
import ProcessState from '../context/process/ProcessState';
import UserProcessState from '../context/userProcess/userProcessState';
import DashboardState from '../context/dashboard/dashboardState';
import PartnerInstitutionState from '../context/partnerInstitution/partnerInstitutionState';
import BraceletState from '../context/bracelet/BraceletState';
import RoleAccessState from '../context/roleAccess/RoleAccessState';
import RoleAccessContext from '../context/roleAccess/roleAccessContext';

// loading
import 'react-loading-skeleton/dist/skeleton.css';

// config
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

// pages
const Layout = lazy(() => import('./Layout'));
const Login = lazy(() => import('../pages/login/Login'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
const Error = lazy(() => import('../pages/error/Error'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const HomePage = lazy(() => import('../pages/public/HomePage'));

export default function App() {
  return (
    <BraceletState>
      <PartnerInstitutionState>
        <DashboardState>
          <UserProcessState>
            <ProcessState>
              <InstanceState>
                <GameState>
                  <TestimonialState>
                    <LawState>
                      <PreventionState>
                        <MunicipalityState>
                          <DepartmentState>
                            <UserState>
                              <RoleAccessState>
                                <AuthState>
                                  <SnackbarProvider maxSnack={3}>
                                    <BrowserRouter>
                                      <Switch>
                                        <Suspense fallback={<CircularLoading height={window.innerHeight} />}>
                                          <Route exact path="/" component={HomePage} />
                                          <Route exact path="/app" render={() => <Redirect to="/app/home" />} />
                                          <PrivateRoute path="/app" component={Layout} />
                                          <PublicRoute path="/login" component={Login} />
                                          <Route exact path="/auth/forgotpassword" render={() => <ForgotPassword />} />
                                          <Route exact path="/auth/resetpassword/:slug" render={() => <ResetPassword />} />
                                          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                                        </Suspense>
                                        <Route render={() => <Error />} />
                                      </Switch>
                                    </BrowserRouter>
                                  </SnackbarProvider>
                                </AuthState>
                              </RoleAccessState>
                            </UserState>
                          </DepartmentState>
                        </MunicipalityState>
                      </PreventionState>
                    </LawState>
                  </TestimonialState>
                </GameState>
              </InstanceState>
            </ProcessState>
          </UserProcessState>
        </DashboardState>
      </PartnerInstitutionState>
    </BraceletState>
  );

  function PrivateRoute({ component, ...rest }) {
    const authContext = useContext(AuthContext);
    const roleAccessContext = useContext(RoleAccessContext);

    const { isAuthenticated, loading, user, loadUser, logout } = authContext;
    const { loadRoleAccess } = roleAccessContext;

    const [accessLoaded, setAccessLoaded] = useState(false);

    useEffect(() => {
      loadUser();
      // eslint-disable-next-line
    }, []);

    // Reset accessLoaded when user logs out
    useEffect(() => {
      if (!isAuthenticated) {
        setAccessLoaded(false);
      }
    }, [isAuthenticated]);

    // Load access menu user
    useEffect(() => {
      if (user &&
        user.data &&
        user.data._id &&
        !accessLoaded) {
        loadRoleAccess(user.data._id);
        setAccessLoaded(true);
      }
      // eslint-disable-next-line
    }, [user, accessLoaded]);

    // Check if user are blocked
    useEffect(
      () => {
        if (!loading) {
          if (user && user.data && !user.data.accessWeb) {
            logout();
          }
        }
      },
      // eslint-disable-next-line
      [loading]
    );

    return (
      <>
        {isAuthenticated &&
          loading === false &&
          user &&
          user.data &&
          user.data.accessWeb && (
            <Route
              {...rest}
              render={(props) => React.createElement(component, props)}
            />
          )}

        {!isAuthenticated && loading === false && (
          <Route
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    from: props.location,
                  },
                }}
              />
            )}
          />
        )}
      </>
    );
  }

  function PublicRoute({ component, ...rest }) {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, loading, user, loadUser, logout } = authContext;

    useEffect(() => {
      loadUser();
      // eslint-disable-next-line
    }, []);

    // Check if user are blocked
    useEffect(
      () => {
        if (!loading) {
          if (user && user.data && !user.data.accessWeb) {
            logout();
          }
        }
      },
      // eslint-disable-next-line
      [loading]
    );

    return (
      <>
        {isAuthenticated &&
          loading === false &&
          user &&
          user.data &&
          user.data.accessWeb && (
            <Route
              {...rest}
              render={(props) => (
                <Redirect
                  to={{
                    pathname: '/app/home',
                  }}
                />
              )}
            />
          )}

        <Route
          {...rest}
          render={(props) => React.createElement(component, props)}
        />
      </>
    );
  }
}