import React, { useReducer } from 'react';
import axios from 'axios';
import { configJSON } from '../../config/requestConfig';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import UserContext from './userContext';
import userReducer from './userReducer';

import {
  GET_USERS,
  GET_USER,
  SET_LOADING_USERS,
  USERS_ERROR,
  DELETE_USER,
  CLEAR_ERRORS_USERS,
  CLEAR_DATA_USERS,
} from '../types';

const UserState = (props) => {
  const initialState = {
    users: [],
    user: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  // Fetch all users
  const getUsers = async () => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/users`);
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USERS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all users with state true
  const getUsersWithStateTrue = async () => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/users/state`);
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USERS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch single user
  const getUser = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/users/${id}`);
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USERS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new user
  const createUser = async (formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.post(`/api/v1/users`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/users');
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: USERS_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update user
  const updateUser = async (_id, formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(`/api/v1/users/${_id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/users');
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: USERS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Update access mobile user
  const updateAccessMobileUser = async (_id, formData, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(
        `/api/v1/users/${_id}/accessMobile`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: USERS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Update access web user
  const updateAccessWebUser = async (_id, formData, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(`/api/v1/users/${_id}/accessWeb`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: USERS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Update auth user
  const updateAuthUser = async (_id, formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(`/api/v1/users/auth/${_id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/users');
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: USERS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Remove user
  const deleteUser = async (_id, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.delete(`/api/v1/users/${_id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      dispatch({
        type: DELETE_USER,
      });
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: USERS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_USERS,
    });
  };

  // Clear Errors
  const clearErrorsUsers = async () => {
    dispatch({
      type: CLEAR_ERRORS_USERS,
    });
  };

  // Clear Data user
  const clearDataUsers = async () => {
    dispatch({
      type: CLEAR_DATA_USERS,
    });
  };

  return (
    <UserContext.Provider
      value={{
        users: state.users,
        user: state.user,
        errors: state.errors,
        loading: state.loading,
        getUsers,
        getUsersWithStateTrue,
        getUser,
        createUser,
        updateUser,
        updateAccessMobileUser,
        updateAccessWebUser,
        updateAuthUser,
        clearErrorsUsers,
        clearDataUsers,
        deleteUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
